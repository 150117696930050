.m-badge-client {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 44px;
   border-radius: 32px;
   width: fit-content;
   p {
      font-size: 14px;
      font-weight: 700;
      color: var(--Neutral-Black-Black);
      padding: 12px 16px;
      text-wrap: nowrap;
   }
   button {
      display: flex;
      align-items: center;
      svg {
         width: 16px;
         height: 16px;
         color: var(--Neutral-Black-Alpha-40);
      }
   }
   &__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 135px;
      direction: ltr;
      text-align: left;
   }
   &__right {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px 16px;
      height: 100%;
      width: 100%;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      width: 100%;
      p {
         padding: 0;
      }
      &__client {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 124px;
      }
   }
   &--sm {
      height: 24px;
      gap: 2px;
      transition: all 0.2s ease-in;
      p {
         font-size: 10px;
         padding: 4px 4px 4px 8px;
      }
      button {
         svg {
            width: 12px;
            height: 12px;
         }
      }
      &__right {
         display: flex;
         align-items: center;
         gap: 4px;
         padding: 5px 8px;
         height: 100%;
         border-top-right-radius: 32px;
         border-bottom-right-radius: 32px;
         p {
            padding: 0;
         }
      }
   }
   &--smHeight {
      height: 12px;
   }
}
