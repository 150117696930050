.m-avatar {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 24px;
   width: 24px;
   border-radius: 50%;
   background-color: var(--Neutral-Slate-200);
   overflow: hidden;

   svg {
      color: var(--Neutral-Slate-500);
      height: 10px;
      width: 10px;
   }

   &--32 {
      height: 32px;
      width: 32px;

      svg {
         height: 12px;
         width: 12px;
      }
   }

   &--40 {
      height: 40px;
      width: 40px;

      svg {
         height: 14px;
         width: 14px;
      }
   }

   &--48 {
      height: 48px;
      width: 48px;

      svg {
         height: 16px;
         width: 16px;
      }
   }

   &--56 {
      height: 56px;
      width: 56px;

      svg {
         height: 18px;
         width: 18px;
      }
   }

   &--64 {
      height: 64px;
      width: 64px;

      svg {
         height: 20px;
         width: 20px;
      }
   }
}